<template>
  <div class="container">
		<div class=" row align-items-center">
			<div class="col-md-3"></div>

			<div class="col-md-6 main-content">
				<div class="card reset-password">
					<div class="content-holder">
             
					  <div class="card-header">

					  	<p>{{ $t('views.forms.reset_password') }}</p>
					  </div>
					  <div class="card-body">
              <div v-if="requestSent" class="status-mssg" :class="{ 'error-class' : responsecodeError }">{{ responseMssg }}</div>
					  	<p v-if="!submitted">{{ $t('views.forms.reset_password_body') }}</p>
              <div v-else class="div-placeholder"></div>

							<input v-if="!submitted" type="email" class="form-control mb-2 text-field" :class="{ 'first-input': !submitted || !errors.has('Email')} " :placeholder="$t('views.forms.place_holders.email')" v-model="email" name="Email" v-validate="'required|email'">
              <div v-if="submitted" class="red-text m-2 error-mssg">{{ errors.first('Email') }}</div>

					  </div>
					  <div v-if="!submitted" class="card-footer">
              <button class="btn btn-block" @click="validateForm()">{{ $t('views.forms.place_holders.new_password') }}</button>
              <span class="back" @click="goHome()">
              <i :class="['fas','fa-angle-left','mr-2']"></i>Zurück
            </span>
					  </div>
					</div>
				</div>
			</div>

			<div class="col-md-3"></div>
		</div>
  </div>
</template>

<script>
export default {
  name: 'resetPassword',
  data() {
    return {
      submitted: false,
      email: '',
      responseMssg: '',
      requestSent: false,
      responsecodeError: false
    };
  },
  methods: {
  	validateForm() {
      this.submitted = true;
      this.$validator.validate().then(valid => {
        if (valid) {
          this.resetPassword();
        } else {
          this.submitted = false;
        }
      });
    },
    resetPassword() {
      this.$http.post('/reset-password', { 'email': this.email })
        .then(() => {
          this.responseMssg = this.$t('views.forms.responses.reset_success');
          this.responsecodeError = false;

          this.requestSent = true;
        })
        .catch(err => {
          this.responseMssg = this.$t('views.forms.responses.' + err.response.data.error);
          this.responsecodeError = true;

          this.requestSent = true;
          this.submitted = false;
        });
    },
    goHome() {
      this.$router.push('/login');
    }
  }
};
</script>

<style lang="scss" scoped>
.header {
  position: absolute;
  top: 50%;
  left: 50%;
  width:100vw;
  height:100vh;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
  z-index: -1;
}

 .back {
    width: 10%;
    color: white;
    font-size: 12px;
  }

  .back:hover {
    cursor: pointer;
  }

  .container {
  	margin-top: $loginMarginTop;
    height: calc(95vh - 150px);
  }

  .content-holder {
  	width: 90%;
  	margin: auto;
  }

  .card {
    width: 100%;
    border-radius: 0;
    box-shadow: 1px 1px 5px rgba(80, 80, 80, 1);
  }

  .card-header {
  	color: $secondaryColor;
  	font-size: 42px;
  	font-weight: 300;
    letter-spacing: 1px;
    margin-top: 8% !important;
    margin-bottom: 0% !important;
    border: none;
  }

  .card-body {
    // padding-top: 0% !important;
    // padding-bottom: 0% !important;
    // padding-right: 16px !important;
    padding: 1.5rem 1.5rem .5rem 1.5rem;
  }

  .div-placeholder {
    height: 50px !important;
  }

  .card-body p {
    margin-bottom: 2rem !important;
    padding-right: 60px !important;
  }

  .card-footer {
    margin-bottom: 5% !important;
    padding-right: 16px !important;
    border: none;
  } 

  .card-body p {
    color: white;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 0.49px;
  }

  .main-content {
    padding: 0px !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .card-body input {
    border-color: #FFFFFF;
    border-radius: 0%;
    background-color: rgba(80, 80, 80, 0.9);
    color: white;
    opacity: 0.5;
  }

  .reset-password {
  	background-color: rgba(80, 80, 80, 0.9);
  }

  .card-footer .btn {
    color: black;
    background-color: white;
    height: 37px;
    text-align: left;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.15px;
    padding-left: 7%;
    padding-top: 1.5%;
    text-transform: none;
   border-radius: 0 !important;

    &:hover{
      background-color: $secondaryColor;
    }
  }

  .error-mssg {
    font-size: 12px;
    margin-top: 0px !important;
    margin-bottom: 4% !important;
  }

  .status-mssg {
    color: white;
    font-size: 16px;
    margin-top: 4% !important;
    margin-bottom: 4% !important;
  }

  .error-class {
    color: red;
  }


  @media screen and (max-device-width: 1400px) {
    header {
      visibility: hidden;
      clear: both;
      float: left;
      margin: 10px auto 5px 20px;
      width: 28%;
      display: none;
    }
  }
</style>
